import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Hero from '../components/hero'
import Layout from '../components/layout'
import SubscribeForm from '../components/subscribe'
import '../components/index.css'

class RootIndex extends React.Component {

  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.description')
    const [indexPage] = get(this, 'props.data.allContentfulPage.edges')
    const locale = "en"

    return (
      <Layout location={this.props.location} locale={locale}>
        <Helmet title={siteTitle} />
        <div className="wrapper">
          <div className="homepage-intro"
            dangerouslySetInnerHTML={{
              __html: indexPage.node.body.childMarkdownRemark.html
            }}
          />
          <div>
            <SubscribeForm locale={locale} />
          </div>
        </div>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query EnHomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulPage(filter: { contentful_id: {eq: "Myp02wS3gj1N4S84HyDuj" }, node_locale: {eq: "en"} }) {
      edges {
        node {
          body {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
